import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Pkw"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { OtherCards } from "../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_INDEX_PKW } from "../components/Questions/helpers"
import { MoreBenefitsInvitefriend } from "../components/MoreBenefitsInvitefriend"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerTitle = "Специальное <br /> предложение для<br /> клиентов Хоум Банка!"

const bannerDescription = "Получите 1000 баллов кэшбэка <br /> на карту «Халва»*"

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout
      seoData={{
        title: "Выгодные покупки с картой рассрочки Халва",
        description:
          "Закажите карту Халва, совершайте покупки в магазинах-партнерах и получайте баллы! Карту можно оформить онлайн и получить с доставкой курьером!",
      }}
      noHeader
      noFooter
      noIndex={noIndex}
    >
      <NewHeader showHint={false} hasCTA countDownTime={countDownTime} />
      <Banner anotherFontSize orderNum="1" title={bannerTitle} description={bannerDescription} />
      <MoreBenefitsInvitefriend
        title={
          <>
            Как заработать <br /> 1000 баллов кэшбэка
          </>
        }
        orderNum="2"
        variant="inviteFriend"
      />
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="3"
      />
      <FormPKW
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="4"
        onlyPKWPage
        productName="Халва из МП ХКБ"
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца**"
        variant="pkwRedesign"
        orderNum="5"
      />
      <NewCalculator additionalEventInDataLayer orderNum="6" />
      <OtherCards orderNum="7" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="8"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="9"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="10" />
      <Reviews additionalEventInDataLayer orderNum="11" />
      <Questions questionList={QUESTIONS_FOR_INDEX_PKW} additionalEventInDataLayer orderNum="12" />
      <NewFooter ligal={ligal} orderNum="13" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/private-halva/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
